<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="info d-none d-md-flex align-center justify-center"
    >
      <SupportArht />
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/img/arhtLogo.png" />
              <h6 class="subtitle-1">ARHT Engine customer portal</h6>
              <v-alert :value="true" type="success" v-if="checkingHash">
                Please wait
              </v-alert>
              <v-alert
                :value="true"
                type="error"
                v-if="!checkingHash && hashNotValid"
              >
                Not valid invitation
              </v-alert>

              <div v-if="!checkingHash && hashExists">
                <h2 class="font-weight-bold mt-4 white--text">Sign up</h2>

                <v-form
                  ref="form"
                  data-vv-scope="form-sign-up-from-hash"
                  v-if="!goodResult"
                  data-testid="form-sign-up-from-hash"
                >
                  <ul v-if="errors_api" class="error-messages">
                    <li v-for="(v, k) in errors_api" :key="k">{{ v }}</li>
                  </ul>
                  <v-alert
                    :value="true"
                    type="error"
                    v-if="
                      errors.has('form-sign-up-from-hash.general_error_field')
                    "
                  >
                    {{
                      errors.first("form-sign-up-from-hash.general_error_field")
                    }}
                  </v-alert>

                  <v-text-field
                    v-model="first_name"
                    data-vv-name="first_name"
                    v-validate="'required'"
                    label="First name"
                    class="mt-2"
                    required
                    outlined
                    :error-messages="
                      errors.collect('form-sign-up-from-hash.first_name')
                    "
                  ></v-text-field>
                  <v-text-field
                    v-model="last_name"
                    data-vv-name="last_name"
                    v-validate="'required'"
                    label="Last name"
                    class="mt-2"
                    required
                    outlined
                    :error-messages="
                      errors.collect('form-sign-up-from-hash.last_name')
                    "
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    data-vv-name="password"
                    v-validate="'required'"
                    label="Password"
                    hint="Password should have minimum 8 chars, at least 1 uppercase letter, at least 1 number char and 1 special char"
                    required
                    outlined
                    class="mt-2"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                    :error-messages="
                      errors.collect('form-sign-up-from-hash.password')
                    "
                    v-on:keyup.enter="submit"
                  ></v-text-field>

                  <v-btn
                    :disabled="
                      !(
                        errors.items.length === 0 &&
                        password.length > 0 &&
                        first_name.length > 0 &&
                        last_name.length > 0
                      )
                    "
                    color="info"
                    block
                    class="mr-4 mt-4"
                    submit
                    @click="submit"
                    >Sign Up
                  </v-btn>

<!--                  <div>-->
<!--                    <v-btn-->
<!--                      :disabled="!microsoftLink"-->
<!--                      color="deep-orange darken-1"-->
<!--                      block-->
<!--                      class="mr-4 mt-6"-->
<!--                      @click="signUpMicrosoft()"-->
<!--                    >-->
<!--                      <v-icon left dark> mdi-microsoft-windows </v-icon>-->
<!--                      Sign Up with microsoft-->
<!--                    </v-btn>-->
<!--                  </div>-->
                </v-form>
                <v-alert type="success" v-if="goodResult">
                  Successful registration
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"
import GeneralMixin from "@/mixins/general"
import SupportArht from "./SupportArht"

export default {
  components: {
    SupportArht,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      checkingHash: true,
      hashExists: false,
      hashNotValid: false,
      showPass: false,
      first_name: "",
      last_name: "",
      password: "",
      hash: "",
      goodResult: false,
      microsoftLink: null,
    }
  },

  mounted() {
    let self = this
    this.hash = this.$route.params.link_hash
    setTimeout(() => {
      this.checkHashExists()
    }, 2000)
    let redirect_url = `${window.location.origin}${
      self.$router.resolve({ name: "sign_up_microsoft" }).href
    }/`
    let payload = {
      params: {
        redirect_uri: redirect_url,
      },
    }
    self.$store.dispatch("getSignUpMicrosoftLink", payload).then((response) => {
      self.microsoftLink = response?.data?.data?.redirect_url
    })
  },

  methods: {
    // checkHashExists
    checkHashExists() {
      let self = this
      self.$store.dispatch("checkHashExists", self.hash).then((response) => {
        // console.log(response)
        if (response && response.status === 200) {
          self.checkingHash = false
          self.hashExists = true
        } else {
          self.checkingHash = false
          self.hashNotValid = true
          setTimeout(() => {
            self.$router.push({ name: "login" }).catch((err) => {})
          }, 2000)
        }
      })
    },
    submit() {
      let self = this
      let formData = {
        slug: self.hash,
        credentials: {
          first_name: self.first_name,
          last_name: self.last_name,
          password: self.password,
        },
      }
      // Should get “Your account is suspended” message if entered correct login info.
      self.$validator
        .validateAll("form-sign-up-from-hash")
        .then((validated) => {
          if (validated) {
            self.$store
              .dispatch("signUpByhashAction", formData)
              .then((result) => {
                if (result && result.status === 200) {
                  self.goodResult = true
                  setTimeout(() => {
                    self.$router.push({ name: "login" }).catch((err) => {})
                  }, 1000)
                } else {
                  self.handleValidationErrors(
                    result.response,
                    "form-sign-up-from-hash"
                  )
                }
              })
              .catch(({ response }) => {
                self.handleValidationErrors(response, "form-sign-up-from-hash")
              })
          }
        })
    },
    signUpMicrosoft() {
      window.location.href = this.microsoftLink
    },
  },

  computed: {
    ...mapState({
      errors_api: (state) => state.auth.errors,
    }),
  },
  beforeDestroy() {
    this.goodResult = false
    this.badResult = false
  },
}
</script>
