var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"info d-none d-md-flex align-center justify-center",attrs:{"cols":"12","lg":"7","xl":"6"}},[_c('SupportArht')],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"5","xl":"6"}},[_c('v-container',[_c('div',{staticClass:"pa-7 pa-sm-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"9","xl":"6"}},[_c('img',{attrs:{"src":require("@/assets/img/arhtLogo.png")}}),_c('h6',{staticClass:"subtitle-1"},[_vm._v("ARHT Engine customer portal")]),(_vm.checkingHash)?_c('v-alert',{attrs:{"value":true,"type":"success"}},[_vm._v(" Please wait ")]):_vm._e(),(!_vm.checkingHash && _vm.hashNotValid)?_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v(" Not valid invitation ")]):_vm._e(),(!_vm.checkingHash && _vm.hashExists)?_c('div',[_c('h2',{staticClass:"font-weight-bold mt-4 white--text"},[_vm._v("Sign up")]),(!_vm.goodResult)?_c('v-form',{ref:"form",attrs:{"data-vv-scope":"form-sign-up-from-hash","data-testid":"form-sign-up-from-hash"}},[(_vm.errors_api)?_c('ul',{staticClass:"error-messages"},_vm._l((_vm.errors_api),function(v,k){return _c('li',{key:k},[_vm._v(_vm._s(v))])}),0):_vm._e(),(
                      _vm.errors.has('form-sign-up-from-hash.general_error_field')
                    )?_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v(" "+_vm._s(_vm.errors.first("form-sign-up-from-hash.general_error_field"))+" ")]):_vm._e(),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mt-2",attrs:{"data-vv-name":"first_name","label":"First name","required":"","outlined":"","error-messages":_vm.errors.collect('form-sign-up-from-hash.first_name')},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mt-2",attrs:{"data-vv-name":"last_name","label":"Last name","required":"","outlined":"","error-messages":_vm.errors.collect('form-sign-up-from-hash.last_name')},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mt-2",attrs:{"data-vv-name":"password","label":"Password","hint":"Password should have minimum 8 chars, at least 1 uppercase letter, at least 1 number char and 1 special char","required":"","outlined":"","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password',"error-messages":_vm.errors.collect('form-sign-up-from-hash.password')},on:{"click:append":function($event){_vm.showPass = !_vm.showPass},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"mr-4 mt-4",attrs:{"disabled":!(
                        _vm.errors.items.length === 0 &&
                        _vm.password.length > 0 &&
                        _vm.first_name.length > 0 &&
                        _vm.last_name.length > 0
                      ),"color":"info","block":"","submit":""},on:{"click":_vm.submit}},[_vm._v("Sign Up ")])],1):_vm._e(),(_vm.goodResult)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" Successful registration ")]):_vm._e()],1):_vm._e()],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }